import classnames from "classnames";
import { TCatalog } from "../../../types";
import styles from "./styles/machineCatalog.module.scss";

type MachineCatalogProps = {
    machine: TCatalog;
    clickHandler: (code: string) => void;
};

export const MachineCatalog = ({
  machine,
  clickHandler,
}: MachineCatalogProps) => (
  <div
    className={classnames(styles.item)}
    onClick={clickHandler.bind(null, machine.code)}
    tabIndex={0}
  >
        <strong className={styles.strong}>
            {machine.name}
        </strong>
  </div>
);
