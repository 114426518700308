// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".m92pVpKVvYvGT1jAkHvR{display:grid;grid-template-columns:repeat(auto-fill, minmax(305px, 1fr));gap:24px}.G6SpjHaa1kWM67ZZpbLB{display:flex;justify-content:space-between;align-items:baseline}@media(max-width: 768.02px){.G6SpjHaa1kWM67ZZpbLB{flex-direction:column-reverse;gap:16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "m92pVpKVvYvGT1jAkHvR",
	"myMachinesHeader": "G6SpjHaa1kWM67ZZpbLB"
};
export default ___CSS_LOADER_EXPORT___;
