import { TRegisteredMachine } from "../../types";
import styles from "./machine.module.scss";

type MachineProps = TRegisteredMachine & {
  onMachineSelected?: (id: string) => void;
};

export const Machine = ({
    id,
    salesModel,
    operationModel,
    customName,
    serialNumber,
    imageUrl,
    onMachineSelected = () => {},
}: MachineProps) => {
    const preloadState = (window as any).app.preloadState.selectMachine;
    const translationState = (window as any).app.preloadState.translation;
    const onMachineClick = async () => {
        await fetch("/api/user/selected-vehicle", {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            },
            body: JSON.stringify({
                CustomName: customName,
                SalesModel: salesModel,
                OperationModel: operationModel,
                SerialNumber: serialNumber
            }),
            method: 'PUT'
        });

        window._paq?.push(['trackEvent', 'Buttons', 'Clicked', 'Machine Selection', salesModel]);

        const urlParams = new URLSearchParams(location.search);

        if (urlParams.get('goBack') === "true" && document.referrer) {
            const url = document.referrer;
            const char = url.includes('?') ? '&' : '?';
            location.assign(url.includes('explodedView') ? url : `${url}${char}explodedView=true`);
        } else {
            location.assign(preloadState.sparePartsUrl);
        }
    }

  return (
    <div className={styles.machine} onClick={() => onMachineSelected(id)}>
          <img width="257" src={imageUrl + "?width=257"} alt="" />
      <div>
              <p className={styles.name}>{customName}</p>
              <p className={styles.model}>{salesModel}</p>
              <p className={styles.serial}>#{serialNumber}</p>
      </div>
          <div className={styles.buttonWrapper}>
              <button className="btn btn--cta firstLetterCapital" onClick={onMachineClick}>{translationState["machineSelection.selectMachine"]}</button>
      </div>
    </div>
  );
};
