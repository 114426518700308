// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._y8m1ceiH6zsk99z0OT4{padding:24px;background:#fff}._y8m1ceiH6zsk99z0OT4 img{height:220px;width:100%;object-fit:contain}.EXOFcks6dWHfyJuoyIt7{display:flex;justify-content:center}.DO7UDXjWtSwkX1wjJj5D{color:#434B4FCC;margin-bottom:8px;min-height:1.8rem}@media(min-width: 0){.DO7UDXjWtSwkX1wjJj5D{font-size:1.3rem;line-height:1.8rem}}@media(min-width: 400px){.DO7UDXjWtSwkX1wjJj5D{font-size:1.3rem;line-height:1.8rem}}@media(min-width: 1366px){.DO7UDXjWtSwkX1wjJj5D{font-size:1.4rem;line-height:2.1rem}}.ij1ogqlau5Ffvk77Jm_Y{font-weight:600;margin-bottom:8px}._vgf2sNfKJ5zTqj0f6pk{font-weight:400;color:#303336;margin-bottom:24px}@media(min-width: 0){._vgf2sNfKJ5zTqj0f6pk{font-size:1.3rem;line-height:1.8rem}}@media(min-width: 400px){._vgf2sNfKJ5zTqj0f6pk{font-size:1.3rem;line-height:1.8rem}}@media(min-width: 1366px){._vgf2sNfKJ5zTqj0f6pk{font-size:1.4rem;line-height:2.1rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"machine": "_y8m1ceiH6zsk99z0OT4",
	"buttonWrapper": "EXOFcks6dWHfyJuoyIt7",
	"name": "DO7UDXjWtSwkX1wjJj5D",
	"model": "ij1ogqlau5Ffvk77Jm_Y",
	"serial": "_vgf2sNfKJ5zTqj0f6pk"
};
export default ___CSS_LOADER_EXPORT___;
