// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SPUqGa3i98M7resRP3kN{display:flex;flex-direction:column;gap:8px;align-items:flex-start}.j4BZGCMOgpQeIMn1tUbf{cursor:pointer;margin-left:10px;margin-bottom:2px;vertical-align:middle}.j4BZGCMOgpQeIMn1tUbf:after{width:24px;height:24px;display:inline-block;background-repeat:no-repeat;content:\"\";background-image:url(\"../assets/icons/Help-tip-gray.svg\")}.j4BZGCMOgpQeIMn1tUbf:after{background-size:24px 24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SPUqGa3i98M7resRP3kN",
	"iconForHoveredText": "j4BZGCMOgpQeIMn1tUbf"
};
export default ___CSS_LOADER_EXPORT___;
