// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wv9Qcpaq7I09QpY65CUO{gap:8px}div.b052cUHvokU1jtOYUrps{position:relative;width:305px;height:56px}@media(min-width: 0){div.b052cUHvokU1jtOYUrps{margin:30px 0}}@media(min-width: 400px){div.b052cUHvokU1jtOYUrps{margin:30px 0}}@media(min-width: 768px){div.b052cUHvokU1jtOYUrps{margin:35px 0}}div.b052cUHvokU1jtOYUrps:after{width:28px;height:28px;display:inline-block;background-repeat:no-repeat;content:\"\";background-image:url(\"../assets/icons/Search.svg\")}div.b052cUHvokU1jtOYUrps>input[type=text]{position:relative;z-index:999;margin-top:0;background-color:#fff;border-radius:28px !important;border-bottom:0}@media(min-width: 0){div.b052cUHvokU1jtOYUrps>input[type=text]{padding:12px 36px 12px 24px}}@media(min-width: 400px){div.b052cUHvokU1jtOYUrps>input[type=text]{padding:12px 36px 12px 24px}}@media(min-width: 768px){div.b052cUHvokU1jtOYUrps>input[type=text]{padding:14px 42px 14px 28px}}div.b052cUHvokU1jtOYUrps:after{z-index:999;position:absolute;right:16px;top:calc(50% - 14px);background-size:28px 28px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "Wv9Qcpaq7I09QpY65CUO",
	"searchInput": "b052cUHvokU1jtOYUrps"
};
export default ___CSS_LOADER_EXPORT___;
