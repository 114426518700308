// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ej8WHEQv0GyQITIpwE_W{display:grid;grid-template-columns:repeat(auto-fill, minmax(305px, 1fr));gap:24px;margin-bottom:80px}@media(max-width: 768.02px){.Ej8WHEQv0GyQITIpwE_W{grid-template-columns:repeat(auto-fill, minmax(245px, 1fr))}}@media(max-width: 576.02px){.Ej8WHEQv0GyQITIpwE_W{grid-template-columns:repeat(auto-fill, minmax(160px, 1fr))}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "Ej8WHEQv0GyQITIpwE_W"
};
export default ___CSS_LOADER_EXPORT___;
