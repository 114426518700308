// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QOnQ8dsy2BBTyJuY02h_{background:#fff;display:inline-block;padding:14px 64px 14px 40px;position:relative;cursor:pointer}@media(min-width: 0){.QOnQ8dsy2BBTyJuY02h_{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 400px){.QOnQ8dsy2BBTyJuY02h_{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 768px){.QOnQ8dsy2BBTyJuY02h_{font-size:1.8rem;line-height:2.8rem}}@media(min-width: 1366px){.QOnQ8dsy2BBTyJuY02h_{font-size:1.9rem;line-height:2.8rem}}.QOnQ8dsy2BBTyJuY02h_::after{display:inline-block;background-repeat:no-repeat;content:\"\";background-image:url(\"../assets/icons/Next.svg\");position:absolute;top:50%;right:20px;transform:translateY(-50%);width:28px;height:28px;background-size:contain}.XLCYIp91R4GFSEzmUAPC{font-weight:700}.LfkpY1BnxX7lcqnRReFI{color:#789;margin-left:8px}@media(min-width: 0){.LfkpY1BnxX7lcqnRReFI{font-size:1.3rem;line-height:1.8rem}}@media(min-width: 400px){.LfkpY1BnxX7lcqnRReFI{font-size:1.3rem;line-height:1.8rem}}@media(min-width: 1366px){.LfkpY1BnxX7lcqnRReFI{font-size:1.4rem;line-height:2.1rem}}.yT2fl8_A4CyUnk_3XmAU{color:#789;margin-left:6px}@media(min-width: 0){.yT2fl8_A4CyUnk_3XmAU{font-size:1.3rem;line-height:1.8rem}}@media(min-width: 400px){.yT2fl8_A4CyUnk_3XmAU{font-size:1.3rem;line-height:1.8rem}}@media(min-width: 1366px){.yT2fl8_A4CyUnk_3XmAU{font-size:1.4rem;line-height:2.1rem}}.Hz2A38VCZKErZKE8jQHo{background:#fc3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "QOnQ8dsy2BBTyJuY02h_",
	"strong": "XLCYIp91R4GFSEzmUAPC",
	"catalogName": "LfkpY1BnxX7lcqnRReFI",
	"serial": "yT2fl8_A4CyUnk_3XmAU",
	"selected": "Hz2A38VCZKErZKE8jQHo"
};
export default ___CSS_LOADER_EXPORT___;
