import classNames from "classnames";
import styles from "./machine.module.scss";
import { memo } from "react";

type MachineProps = {
  code: string;
  name: string;
  imageUrl: string;
  active: boolean;
  onClick: (code: string) => void;
};

export const Machine = memo(
  ({ code, name, imageUrl, active, onClick }: MachineProps) => {
    return (
      <div tabIndex={0}
        className={classNames(styles.machine, {
          [styles.activeMachine]: active,
        })}
        onClick={onClick.bind(null, code)}
        style={{justifyContent: imageUrl ? "space-between" : "center" }}
        >
            {imageUrl && <img className={styles.machineImage + "?height=170"} src={imageUrl} alt={name} />}
        <p className={styles.name}>{name}</p>
      </div>
    );
  }
);
