// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R5QZI3xx9Svf9nP7pmuQ{background:#fff;display:inline-block;padding:14px 64px 14px 40px;position:relative;cursor:pointer}@media(min-width: 0){.R5QZI3xx9Svf9nP7pmuQ{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 400px){.R5QZI3xx9Svf9nP7pmuQ{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 768px){.R5QZI3xx9Svf9nP7pmuQ{font-size:1.8rem;line-height:2.8rem}}@media(min-width: 1366px){.R5QZI3xx9Svf9nP7pmuQ{font-size:1.9rem;line-height:2.8rem}}.R5QZI3xx9Svf9nP7pmuQ::after{display:inline-block;background-repeat:no-repeat;content:\"\";background-image:url(\"../assets/icons/Next.svg\");position:absolute;top:50%;right:20px;transform:translateY(-50%);width:28px;height:28px;background-size:contain}.lfBnBTpzkjIFOJ9AdxYx{font-weight:700}.CU1egZuyTLFD4agb9OMd{background:#fc3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "R5QZI3xx9Svf9nP7pmuQ",
	"strong": "lfBnBTpzkjIFOJ9AdxYx",
	"selected": "CU1egZuyTLFD4agb9OMd"
};
export default ___CSS_LOADER_EXPORT___;
